@use 'sass:list';

// ---------------------------------------------------------

@use './color' as *;
@use './font' as *;
@use './global' as *;
@use './z-index' as *;

// ---------------------------------------------------------

:root {
  // ------------------------------------------------------- | color variables

  @each $variable in $color {
    #{list.nth($variable, 1)}: #{list.nth($variable, 2)};
  }

  // ------------------------------------------------------- | font variables

  @each $variable in $font {
    #{list.nth($variable, 1)}: #{list.nth($variable, 2)};
  }

  // ------------------------------------------------------- | global variables

  @each $variable in $global {
    #{list.nth($variable, 1)}: #{list.nth($variable, 2)};
  }

  // ------------------------------------------------------- | z-index variables

  @each $variable in $z-index {
    #{list.nth($variable, 1)}: #{list.nth($variable, 2)};
  }
}
