@use 'global' as *;
@use 'sass:list';

// ---------------------------------------------------------

.container {
  display: grid;
  position: relative;

  > * {
    grid-column-start: 1;
    grid-row-start: 1;
  }

  &.theme_background_is_white {
    &:last-of-type {
      .container_content {
        margin-bottom: rfs(60px);
      }
    }
  }
}

.container_content {
  display: flex;
  flex-direction: column;
  position: relative;

  > div {
    width: 100%;
  }
}

.container_header {
  margin-bottom: rfs(10px);

  h2 {
    margin-bottom: 12px;
  }

  h3 {
    margin: 0 auto 10px;
    max-width: rfs(650px);
    text-align: center;

    @include body-b1;
  }

  a {
    align-items: center;
    color: var(--color-gray-5);
    display: inline-flex;
    font-family: var(--font-family-medium);
    margin-bottom: 12px;

    @include font-size(12px, 24px);
  }
}

.container_description {
  @include font-size(15px, 24px);
}

.header_description_container {
  display: flex;
  flex-direction: column;
}

.header_spacing {
  &_none {
    margin-bottom: 0 !important;
  }

  &_small {
    margin-bottom: 10px !important;
  }

  &_medium {
    margin-bottom: 20px !important;
  }

  &_large {
    margin-bottom: 30px !important;
  }
}

.container_alignment_center {
  .header_description_container {
    align-items: center;
  }
}

.container_alignment_left {
  .header_description_container {
    align-items: flex-start;
  }
}

.container_alignment_right {
  .header_description_container {
    align-items: flex-end;
  }
}

// --------------------------------------------------------

$spacing-classes: (('none', 0), ('small', 25px), ('medium', 40px), ('large', 60px));

@each $spacing in $spacing-classes {
  .spacing_#{list.nth($spacing, 1)} {
    @if list.nth($spacing, 1) != 'none' {
      margin-bottom: rfs(list.nth($spacing, 2));
      margin-top: rfs(list.nth($spacing, 2));
    }

    &.remove_bottom_spacing {
      margin-bottom: 0 !important;
    }
  }
}

// ---------------------------------------------------------

.text_alignment_center {
  align-items: center;

  p,
  h2,
  legend {
    margin-left: auto;
    margin-right: auto;
    max-width: 766px;
    text-align: center;
  }
}

.text_alignment_left {
  text-align: left;
}

.text_alignment_right {
  text-align: right;
}

// ---------------------------------------------------------

.theme_background_is_white {
  background-color: var(--color-white);
  color: var(--color-black);
}

.theme_background_is_gray {
  background-color: var(--color-gray-1);
  color: var(--color-black);
}

.theme_background_is_black {
  background-color: var(--color-black);
  color: var(--color-white);

  a {
    color: var(--color-white);
    text-decoration: underline;
  }
}

.theme_background_gray_to_white {
  background-color: none;
  background-image: linear-gradient(to bottom, var(--color-gray-1), var(--color-white));
}

.theme_has_white_content_box {
  .container_content {
    background-color: var(--color-white);
    box-shadow: 0 0 12px 0 rgb(0 0 0 / 10%), 0 0 2px 0 rgb(0 0 0 / 10%);
    max-width: rfs(1038px);
    padding: rfs(72px 58px 58px);
    width: calc(100% - 40px);
  }

  &.no_padding {
    .container_content {
      padding: 0 !important;
    }
  }
}

.theme_has_grey_content_box {
  .container_content {
    background-color: var(--color-gray-1);
    box-shadow: 0 0 12px 0 rgb(0 0 0 / 10%), 0 0 2px 0 rgb(0 0 0 / 10%);
    max-width: rfs(1038px);
    padding: rfs(72px 58px 58px);
    width: calc(100% - 40px);
  }

  &.no_padding {
    .container_content {
      padding: 0 !important;
    }
  }
}

.theme_has_background_image_with_content_box_shadow {
  .background_image {
    img {
      width: 100%;
    }
  }

  .container_content {
    > * {
      grid-column-start: 1;
      grid-row-start: 1;

      @include media($small) {
        padding: rfs(72px 58px 58px);
      }
    }
  }

  @include media($small) {
    .content_box_shadow {
      background-color: rgb(255 255 255 / 90%);
      box-shadow: 0 0 12px 0 rgb(0 0 0 / 10%), 0 0 2px 0 rgb(0 0 0 / 10%);
      width: 100%;
    }
  }
}

// ---------------------------------------------------------

.width_full {
  @include container;

  width: 100%;

  > div {
    width: 100%;
  }

  &.no_overflow {
    overflow: unset;
  }
}

.width_fixed {
  @include container;

  max-width: rfs(1038px);
  padding-left: var(--global-padding);
  padding-right: var(--global-padding);
  width: 100%;

  @include media($small) {
    padding: 0;
  }
}

.width_fixed_medium {
  @include container;

  max-width: rfs(700px);
  padding-left: var(--global-padding);
  padding-right: var(--global-padding);
  width: 100%;

  > div {
    width: 100%;
  }
}

.width_fixed_large {
  @include container;

  max-width: rfs(920px);
  padding-left: var(--global-padding);
  padding-right: var(--global-padding);
  width: 100%;

  @include media($small) {
    padding: 0;
  }

  > div {
    width: 100%;
  }
}
