@use 'global' as *;
.card {
  align-self: stretch;
  background-color: var(--color-white);
  border-radius: 4px;
  box-shadow: 0 0 12px 0 rgb(0 0 0 / 10%), 0 0 2px 0 rgb(0 0 0 / 10%);
  container-type: inline-size;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  max-width: 350px;
  min-height: 150px;
  overflow: hidden;
  transition: color 0.25s ease-out;
  width: 100%;

  &.no_image {
    .inner_content_container {
      height: 100%;
      padding: 14px 16px;

      .inner_content {
        padding-top: 0;
      }
    }
  }

  &.no_contact {
    min-height: unset;
  }

  @container (max-width: 275px) {
    .contact_content {
      @include font-size(10px, 18px);

      svg {
        width: 14px;
      }
    }
  }
}

.inner_content_container {
  display: flex;
  gap: 16px;
  min-height: 125px;
}

.inner_content {
  height: 100%;
  padding-top: 14px;
  width: 100%;

  .name_heading {
    @include font-size(24px, 28px);

    color: var(--color-black);
    font-family: var(--font-family-base);
    letter-spacing: -0.5px;
    margin-bottom: 0;
  }

  .title_heading {
    @include font-size(10px, 18px);

    color: var(--color-gray-4);
    font-family: var(--font-family-medium);
    letter-spacing: 0.5px;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  .company_heading {
    @include font-size(12px, 20px);

    font-family: var(--font-family-medium);
    letter-spacing: 0.5px;
  }
}

.image_container {
  max-width: 25%;
  position: relative;
  width: 100%;

  > div {
    height: 100%;

    img {
      height: 100%;
    }
  }
}

.contact_content {
  @include font-size(12px, 18px);

  align-self: flex-end;
  background-color: var(--color-gray-1);
  color: var(--color-gray-5);
  container-type: inline-size;
  display: flex;
  font-family: var(--font-family-medium);
  grid-area: 2 / 2 / 3 / 5;
  justify-content: center;
  margin-top: auto;
  max-height: 40px;
  min-width: 0;
  padding: 6px 6px 7px 0;
  width: 100%;

  @media (max-width: $small) {
    grid-area: 2 / 1 / 3 / 5;
  }
}

.contact_link {
  display: flex;
  justify-content: center;
  padding: 8px 0;
  width: 105px;

  a {
    align-items: center;
    color: var(--color-gray-5);
    display: flex;
    padding: rfs(0 16px);

    svg {
      margin-right: 4px;
    }
  }

  button {
    align-items: center;
    color: var(--color-gray-5);
    display: flex;
    font-family: var(--font-family-medium);
    font-size: 12px;
    padding: rfs(0 16px);

    svg {
      margin-right: 6px;
    }
  }

  button:hover {
    text-decoration: underline;
  }
}
